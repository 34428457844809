import deIcon from "assets/svg/locales/de.svg";
import enIcon from "assets/svg/locales/en.svg";
import esIcon from "assets/svg/locales/es.svg";
import frIcon from "assets/svg/locales/fr.svg";
import jaIcon from "assets/svg/locales/ja.svg";
import koIcon from "assets/svg/locales/ko.svg";
import ruIcon from "assets/svg/locales/ru.svg";
import zhIcon from "assets/svg/locales/zh.svg";
import map from "lodash/map";

export const LOCALES = [
  { key: "en", name: "English" },
  // { key: 'ko', name: 'Korean', icon: koIcon },
  // { key: 'es', name: 'Spanish', icon: esIcon },
  // { key: 'ja', name: 'Japanese', icon: jaIcon },
  // { key: 'ru', name: 'Russian', icon: ruIcon },
  // { key: 'de', name: 'German', icon: deIcon },
  // { key: 'fr', name: 'French', icon: frIcon },
  // { key: 'zh', name: 'China', icon: zhIcon }
];
export const SUPPORTED_LOCALES = map(LOCALES, "key");

export type SupportedLocale = (typeof SUPPORTED_LOCALES)[number] | "pseudo";

export const DEFAULT_LOCALE: SupportedLocale = "en";
