import React from "react";

import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.5858C17.28 22.5858 21.6 18.2658 21.6 12.9858C21.6 7.70584 17.28 3.38584 12 3.38584C6.72 3.38584 2.4 7.70584 2.4 12.9858C2.4 18.2658 6.72 22.5858 12 22.5858ZM12 0.98584C18.6 0.98584 24 6.38584 24 12.9858C24 19.5858 18.6 24.9858 12 24.9858C5.4 24.9858 0 19.5858 0 12.9858C0 6.38584 5.4 0.98584 12 0.98584Z"
        fill="#F04A54"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5818 17.4897C15.1144 18.0223 15.9778 18.0223 16.5104 17.4897C17.043 16.9572 17.043 16.0937 16.5104 15.5612L13.9396 12.9903L16.5007 10.4291C17.0333 9.89658 17.0333 9.03312 16.5007 8.50056C15.9682 7.96799 15.1047 7.96799 14.5721 8.50056L12.011 11.0617L9.43892 8.48967C8.90636 7.9571 8.0429 7.9571 7.51033 8.48967C6.97777 9.02223 6.97777 9.88569 7.51033 10.4183L10.0824 12.9903L7.50064 15.5721C6.96808 16.1046 6.96808 16.9681 7.50064 17.5006C8.03321 18.0332 8.89666 18.0332 9.42923 17.5006L12.011 14.9189L14.5818 17.4897Z"
        fill="#F04A54"
      />
    </svg>
  );
};

export default Icon;
