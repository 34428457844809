import { arbitrumGoerli } from "./connector/chains";
import { Env } from "./enum";

export const UNI_WETH_ARBITRUM_GOERLI_DEV = {
  name: "Wrapped Ether",
  address: "0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3",
  symbol: "WETH",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  precision: 2,
  positionUnits: 3,
  sort: -1,
};

export const UNI_WETH_ARBITRUM_GOERLI_PROD = {
  name: "Wrapped Ether",
  address: "0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3",
  symbol: "WETH",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  precision: 2,
  positionUnits: 3,
  sort: -1,
};

export const EQU_ARBITRUM_GOERLI_PROD = {
  name: "EQU Coin",
  address: "0xD5AEf45d7a0076D78083a81334F7F779cA8fc31c",
  symbol: "EQU",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  precision: 4,
  positionUnits: 4,
  sort: -1,
};

export const EQU_ARBITRUM_GOERLI_DEV = {
  name: "EQU Coin",
  address: "0x4372826e0d6E640546BA6590e7f91842DA596E01",
  symbol: "EQU",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  precision: 4,
  positionUnits: 4,
  sort: -1,
};

export const veEQU_ARBITRUM_GOERLI_PROD = {
  name: "EQU Coin",
  address: "0xAD36369561eAdEfA696a1BB9F0F48D9499d215eE",
  symbol: "EQU",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  precision: 4,
  positionUnits: 2,
  sort: -1,
};

export const veEQU_ARBITRUM_GOERLI_DEV = {
  name: "EQU Coin",
  address: "0x10f92eCa26B26bfc4290d067D7801caaf3391f04",
  symbol: "EQU",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  precision: 4,
  positionUnits: 2,
  sort: -1,
};

export const USDT_ARBITRUM_GOERLI_PROD = {
  name: "USD Coin",
  address: "0x58e7F6b126eCC1A694B19062317b60Cf474E3D17",
  symbol: "USDT",
  decimals: 6,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  precision: 2,
  positionUnits: 2,
  sort: 999,
};

export const USDT_ARBITRUM_GOERLI_DEV = {
  name: "USD Coin",
  address: "0xa20Aa54C853348248C472717a65EFfEDb8357e9D",
  symbol: "USDT",
  decimals: 6,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  precision: 2,
  positionUnits: 2,
  sort: 999,
};

export const WBTC_ARBITRUM_GOERLI = {
  name: "Wrapped BTC",
  address: "0x6f0763010F979B83837327FF9a37Ff93cC95A51c",
  symbol: "BTC",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
  precision: 2,
  positionUnits: 4,
  sort: 2,
};

export const WETH_ARBITRUM_GOERLI = {
  name: "Wrapped Ether",
  address: "0xECF628c20E5E1C0e0A90226d60FAd547AF850E0F",
  symbol: "WETH",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  precision: 2,
  positionUnits: 3,
  sort: 1,
};

export const XRP_ARBITRUM_GOERLI = {
  name: "XRP",
  address: "0x3743ED87a3CD6D597151B4A27CE7Dfc5A7BC2149",
  symbol: "XRP",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  precision: 5,
  positionUnits: 1,
  sort: 998,
};

export const DOGE_ARBITRUM_GOERLI = {
  name: "Doge",
  address: "0x91F728b5BA9AE11Aea0895fb001f61D7e407D14A",
  symbol: "DOGE",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  precision: 6,
  positionUnits: 1,
  sort: 997,
};

export const ARB_ARBITRUM_GOERLI = {
  name: "Arbitrum",
  address: "0x3ef7a2c0fa0dbac93421692e7e6f1551b4af696a",
  symbol: "ARB",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  precision: 4,
  positionUnits: 1,
  sort: 3,
};

export const DAI_ARBITRUM_GOERLI = {
  name: "Dai",
  address: "0x8ddfe2752c91f4aa20a5adb7b187c6d6020283d7",
  symbol: "DAI",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  precision: 4,
  positionUnits: 2,
  sort: 5,
};

export const LINK_ARBITRUM_GOERLI = {
  name: "Chainlink",
  address: "0x12fa61ac9929bd6a4fecfcad7316af92ec32bed6",
  symbol: "LINK",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  precision: 4,
  positionUnits: 1,
  sort: 4,
};

export const eLINK_ARBITRUM_GOERLI = {
  name: "Chainlink",
  address: "0xf819e547928b4abf6e80cb62dc4ed14e8090feab",
  symbol: "eLINK",
  decimals: 18,
  chainId: arbitrumGoerli.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  precision: 4,
  positionUnits: 1,
  sort: 4,
};

export const TOKENS_ARBITRUM_GOERLI = [
  (process.env.REACT_APP_TAG as Env) === Env.development
    ? USDT_ARBITRUM_GOERLI_DEV
    : USDT_ARBITRUM_GOERLI_PROD,
  (process.env.REACT_APP_TAG as Env) === Env.development
    ? EQU_ARBITRUM_GOERLI_DEV
    : EQU_ARBITRUM_GOERLI_PROD,
  UNI_WETH_ARBITRUM_GOERLI_DEV,
  WBTC_ARBITRUM_GOERLI,
  WETH_ARBITRUM_GOERLI,
  XRP_ARBITRUM_GOERLI,
  DOGE_ARBITRUM_GOERLI,
  ARB_ARBITRUM_GOERLI,
  DAI_ARBITRUM_GOERLI,
  LINK_ARBITRUM_GOERLI,
  eLINK_ARBITRUM_GOERLI,
];
