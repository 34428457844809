import Banner from "./banner";
import Cards from "./Cards";
import Blogs from "./Blogs";
// import Founders from "./Founders";
import Hiring from "./Hiring";
import ContactUs from "./ContactUs";

function Index() {
  return (
    <div className="relative -mt-2 sm:mt-0">
      <Banner />
      <Cards />
      <Blogs />
      {/* <Founders /> */}
      <Hiring />
      <ContactUs />
    </div>
  );
}

export default Index;
