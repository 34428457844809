import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import BaseLayout from "../layout";
import Index from "../pages/index";
import NotFound from "../pages/404/index";

const AppRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<BaseLayout />}>
        <Route path="/" element={<Index />} />
      </Route>
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  );
};

export default AppRouter;
