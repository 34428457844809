import { Reducer, createSlice } from "@reduxjs/toolkit";
import { Transaction_Type } from "config/constants/enum";

import { produce } from "immer";
import { RootState } from "state";
import { BaseRecord, TokenInfo } from "types";

interface StateProps {
  visibleSuccess: boolean;
  visibleError: boolean;
  txErrorMessage: string;
  transactionType: Transaction_Type;
  signingMap: Map<Transaction_Type, BaseRecord | null>;
  quoteToken: TokenInfo | null;
  quoteBalance: string;
  nativeBalance: string;
  nativeSymbol: string;
  appToken: TokenInfo | null;
  appTokenBalance: string;
  txDescription: string;
}

const initialState: StateProps = {
  visibleSuccess: false,
  visibleError: false,
  txErrorMessage: "",
  transactionType: Transaction_Type.Long,
  signingMap: new Map<Transaction_Type, BaseRecord | null>(),
  quoteToken: null,
  quoteBalance: "",
  nativeBalance: "",
  nativeSymbol: "",
  appToken: null,
  appTokenBalance: "",
  txDescription: "",
};

export const slice = createSlice({
  name: "tx",
  initialState,
  reducers: {
    setVisibleSuccess(state, { payload }) {
      state.visibleSuccess = payload;
    },
    setVisibleError(state, { payload }) {
      state.visibleError = payload;
    },
    setSigningMap(state, { payload }) {
      state.signingMap = payload;
    },
    updateSigning(state, { payload }: { payload: BaseRecord | null }) {
      produce(state.signingMap, () => {
        if (payload) {
          const current = new Map(state.signingMap);
          current.set(payload.transactionType, payload);
          state.signingMap = current;
        }
      });
    },
    removeSigning(state, { payload }: { payload: BaseRecord | null }) {
      produce(state.signingMap, () => {
        if (payload) {
          const current = new Map(state.signingMap);
          current.delete(payload.transactionType);
          state.signingMap = current;
        }
      });
    },
    setTxErrorMessage(state, { payload }) {
      state.txErrorMessage = payload;
    },
    setQuoteToken(state, { payload }) {
      state.quoteToken = payload;
    },
    setQuoteBalance(state, { payload }) {
      state.quoteBalance = payload;
    },
    setNativeBalance(state, { payload }) {
      state.nativeBalance = payload;
    },
    setNativeSymbol(state, { payload }) {
      state.nativeSymbol = payload;
    },
    setAppToken(state, { payload }) {
      state.appToken = payload;
    },
    setAppBalance(state, { payload }) {
      state.appTokenBalance = payload;
    },
    setTransactionType(state, { payload }) {
      state.transactionType = payload;
    },
    setTxDescription(state, { payload }) {
      state.txDescription = payload;
    },
    reset(state) {
      state.txErrorMessage = "";
      produce(state.signingMap, () => {
        state.signingMap = new Map();
      });
    },
  },
});

export const {
  setVisibleError,
  setVisibleSuccess,
  setTxErrorMessage,
  setSigningMap,
  updateSigning,
  removeSigning,
  setQuoteToken,
  setQuoteBalance,
  setNativeBalance,
  setNativeSymbol,
  setTransactionType,
  setTxDescription,
  setAppToken,
  setAppBalance,
  reset,
} = slice.actions;

export const txBaseState = (state: RootState) => state.tx;

export default slice.reducer as Reducer<typeof initialState>;
