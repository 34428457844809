import { arbitrum } from "./connector/chains";

export const UNI_WETH_ARBITRUM = {
  name: "Wrapped Ether",
  address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
  symbol: "WETH",
  decimals: 18,
  chainId: arbitrum.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  precision: 2,
  positionUnits: 3,
  sort: -1,
};

export const EQU_ARBITRUM = {
  name: "EQU Coin",
  address: "0x87AAfFdF26c6885f6010219208D5B161ec7609c0",
  symbol: "EQU",
  decimals: 18,
  chainId: arbitrum.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  precision: 4,
  positionUnits: 4,
  sort: -1,
};

export const veEQU_ARBITRUM = {
  name: "veEQU Coin",
  address: "0xD9D25C67D0CAf9B8D8Df60C5038a18EC951f92C9",
  symbol: "veEQU",
  decimals: 18,
  chainId: arbitrum.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  precision: 4,
  positionUnits: 2,
  sort: -1,
};

export const USDT_ARBITRUM = {
  name: "USD Coin",
  address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
  symbol: "USDT",
  decimals: 6,
  chainId: arbitrum.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
  precision: 2,
  positionUnits: 2,
  sort: 999,
};

export const WETH_ARBITRUM = {
  name: "Wrapped Ether",
  address: "0x23EEF5f7A8b37b2310e9aC9611E35fa999143810",
  symbol: "WETH",
  decimals: 18,
  chainId: arbitrum.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  precision: 2,
  positionUnits: 3,
  sort: 1,
};

export const WBTC_ARBITRUM = {
  name: "Wrapped BTC",
  address: "0x1885aAD80817a0baF196dEb62b08695E64936332",
  symbol: "BTC",
  decimals: 18,
  chainId: arbitrum.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png",
  precision: 2,
  positionUnits: 4,
  sort: 2,
};

export const SOL_ARBITRUM = {
  name: "SOL",
  address: "0x1281b9dAFA2b11C43e1E41bb4aDCdd3bdBc2b2a6",
  symbol: "SOL",
  decimals: 18,
  chainId: arbitrum.id,
  logoURI:
    "https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316",
  precision: 4,
  positionUnits: 1,
  sort: 3,
};

export const ARB_ARBITRUM = {
  name: "Arbitrum",
  address: "0x96A0D2D2D4378bCb35013f964d95224fE4FEd8FE",
  symbol: "ARB",
  decimals: 18,
  chainId: arbitrum.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png",
  precision: 4,
  positionUnits: 1,
  sort: 4,
};

export const OP_ARBITRUM = {
  name: "OP",
  address: "0xA67cB8b1880E2573907f5f52121e8C49B141bD66",
  symbol: "OP",
  decimals: 18,
  chainId: arbitrum.id,
  logoURI: "https://ethereum-optimism.github.io/data/OP/logo.png",
  precision: 4,
  positionUnits: 1,
  sort: 5,
};

export const MATIC_ARBITRUM = {
  name: "MATIC",
  address: "0xA7c1181043e489E720Fb72e33168298675EDCdF8",
  symbol: "MATIC",
  decimals: 18,
  chainId: arbitrum.id,
  logoURI:
    "https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912",
  precision: 4,
  positionUnits: 1,
  sort: 6,
};

export const AVAX_ARBITRUM = {
  name: "AVAX",
  address: "0xB42E185016C53B38C2c61409F1D6DA3CcFB02C9f",
  symbol: "AVAX",
  decimals: 18,
  chainId: arbitrum.id,
  logoURI:
    "https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/avalanchec/assets/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/logo.png",
  precision: 3,
  positionUnits: 2,
  sort: 7,
};

export const LINK_ARBITRUM = {
  name: "LINK",
  address: "0xEE4b6FC76B9F44420D646BFCf8a1F5c4c399B54F",
  symbol: "LINK",
  decimals: 18,
  chainId: arbitrum.id,
  logoURI:
    "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x514910771AF9Ca656af840dff83E8264EcF986CA/logo.png",
  precision: 3,
  positionUnits: 1,
  sort: 8,
};

export const TOKENS_ARBITRUM = [
  WETH_ARBITRUM,
  WBTC_ARBITRUM,
  SOL_ARBITRUM,
  ARB_ARBITRUM,
  OP_ARBITRUM,
  MATIC_ARBITRUM,
  AVAX_ARBITRUM,
  LINK_ARBITRUM,
];
