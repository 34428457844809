import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Typography } from "@mui/material";
// import { NavLink } from "react-router-dom";

// import footerLogoIcon from "../../assets/logo.svg";
import twitterLogoIcon from "../../assets/icons/twitter.svg";
import telegramLogoIcon from "../../assets/icons/telegram.svg";
import discordLogoIcon from "../../assets/icons/discord.svg";
import mediumLogoIcon from "../../assets/icons/medium.svg";
import githubLogoIcon from "../../assets/icons/github.svg";
import { StyleContainer } from "components/Common/StyleContainer";

// const NavsContainer = styled("div")(({ theme }) => ({
//   padding: "0px 120px 0px",
//   "@media screen and (max-width: 768px)": {
//     paddingLeft: "1rem",
//     paddingRight: "1rem",
//     paddingTop: "1.5rem",
//   },
//   "@media screen and (min-width: 769px) and (max-width: 1199px)": {
//     padding: "0px 20px  10px",
//     width: "100%",
//   },
//   "@media screen and (min-width: 1921px)": {
//     padding: "0px 240px  10px",
//   },
// }));

const Footer = () => {
  return (
    <StyleContainer className="pb-1.5 pt-[80px] sm:pt-[40px]">
      <div className="container flex items-center justify-between sm:flex-col-reverse">
        <Typography
          className="text-[18px] sm:text-[16px] sm:mt-1 opacity-40"
          color="#19232D"
        >
          © 2024 Pure, all rights reserved
        </Typography>
        <div className=" space-x-[40px]">
          <a
            href="https://x.com/purecash_labs"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={twitterLogoIcon}
              alt="Twitter"
              className="w-[26px] sm:w-[20px]"
            />
          </a>
          <a
            href=" https://t.me/PURECASH_LABS"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={telegramLogoIcon}
              alt="Telegram"
              className="w-[26px] sm:w-[20px]"
            />
          </a>
          <a
            href="https://discord.gg/pZzhahDQXa"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={discordLogoIcon}
              alt="Medium"
              className="w-[26px] sm:w-[20px]"
            />
          </a>
          <a
            href="https://medium.com/@purecash_labs"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={mediumLogoIcon}
              alt="Discord"
              className="w-[26px] sm:w-[20px]"
            />
          </a>
          <a
            href="https://github.com/pure-cash"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={githubLogoIcon}
              alt="GitHub"
              className="w-[26px] sm:w-[20px]"
            />
          </a>
        </div>
      </div>
    </StyleContainer>
  );
};
export default Footer;
