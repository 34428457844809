export * from "./bignumbers";
export * from "./enum";
export * from "./locales";

export const DEFAULT_TOKEN_URI = "/default-token.svg";
export const DEFAULT_ETH_URI = "/eth.png";
export const DEFAULT_WETH_URI = "/weth.png";

export const DEFAULT_SYMBOL_LEN = 7;

export const MAX_PRECISION = 32;
export const REFERRAL_CODE_LENGTH = 10;

export const NATIVE_BUFFER_FOR_MAX = 0.01;

// PERCENTAGE
export const DEFAULT_SETTING_SLIPPAGE = "0.30";
export const DEFAULT_BALANCE_IMPACT_FEE_RATE = "1";

export const MIN_ORIGIN_NUMBER = 0.00001;
export const MAX_ORIGIN_NUMBER = 100000000000;

export const MIN_AUTO_SLIPPAGE_TOLERANCE = DEFAULT_SETTING_SLIPPAGE;
export const MAX_AUTO_SLIPPAGE_TOLERANCE = "25";

// MINUTES
export const DEFAULT_SETTING_DEADLINE = "20";

export const MAX_PAGE_SIZE = 1000;

export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_MAX_ADVANCED_GRID = 50;

export const DEFAULT_USER_PRECISION = 6;

export const DEFAULT_QUOTE_PRECISION = 6;

export const DEFAULT_CHART_PRECISION = 8;

export const DEFAULT_PRECISION = 18;

export const MAX_USER_INTERER = 12;

export const MAX_DECIMAL = 8;

export const MAX_ORDERSNUMBER_DISPLAY = 99;

export const REG_NUMBER = new RegExp(
  "^[1-9]+[0-9]*(\\.\\d{0," + DEFAULT_PRECISION + "})?$"
);

export const REG_DECIMAL_SIMPLE = /^\d*(\.\d*)?$/;

export const REG_DECIMAL = new RegExp(
  "^0(\\.\\d{0," + DEFAULT_PRECISION + "})?$"
);

export const DEFAULT_ACTION_TICK_NUMBER = 15;

export const DEFAULT_ACTION_TICK_RANGES = DEFAULT_ACTION_TICK_NUMBER * 2 + 1;

export const REG_INTEGER = /^[0-9]*[1-9][0-9]*$/;

export const REG_REFERRAL_CODE = /^\w{1,10}$/;

export const MAX_STORAGE_TX_NUMBER = 500;

export const STORAGE_KEY_POOL_LIQUIDITY = "STORAGE_KEY_POOL_LIQUIDITY";
export const STORAGE_KEY_POOL_TRADE = "STORAGE_KEY_POOL_TRADE";

export const STORAGE_KEY_MAKER_ADVANCED = "STORAGE_KEY_MAKER_ADVANCED";
export const STORAGE_KEY_APP_THEME = "STORAGE_KEY_APP_THEME";
export const STORAGE_KEY_APP_COOKIES_STATUS = "STORAGE_KEY_APP_COOKIES_STATUS";

export const STORAGE_KEY_FROM_TOKEN = "STORAGE_KEY_FROM_TOKEN";
export const STORAGE_KEY_TO_TOKEN = "STORAGE_KEY_TO_TOKEN";
export const STORAGE_KEY_CURRENT_PRICE = "STORAGE_KEY_CURRENT_PRICE";
export const STORAGE_KEY_PRICES = "STORAGE_KEY_PRICES";

export const EVENT_CHART_PRICE = "EVENT_CHART_PRICE";

export const DEBOUNCE_TIME_OUT = 400;

export const MAX_ORDERBOOK_MOBILE_ORDER_NUMBER = 6;

export const GLOBAL_INTERVAL_TIMEOUT = 5000;

export const PRICES_INTERVAL_TIMEOUT = 2000;

export const MARKET_INTERVAL = 5000;

export const TIME_ONE_MINUTE_TO_SECOND = 60000;
export const TIME_DAY_TO_SECOND = 24 * 60 * 60;
export const TIME_7DAY_TO_SECOND = TIME_DAY_TO_SECOND * 7;

export const DEFAULT_BOOK_ITEMS_NUMBER = 200;

export const TRANSACTION_STATUS_RESET = 0; // minutes

export const TRANSACTION_STATUS_TIMEOUT = 90; // seconds

export const DAYS_YEAR = 365;

export const LEVERAGE_MAKERS = [
  {
    value: 1,
    label: "1X",
  },
  {
    value: 20,
    label: "20X",
  },
  {
    value: 40,
    label: "40X",
  },
  {
    value: 60,
    label: "60X",
  },
  {
    value: 80,
    label: "80X",
  },
  {
    value: 100,
    label: "100X",
  },
];
export const AMOUNT_MAKERS = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "100%",
  },
];

export const PRICE_ZERO = "0";

export const QUOTE_USD = "USDT";
export const QUOTE_USD_PRECISION = 6;

export const APP_TOKEN_SYMBOL = "EQU";
export const USDT_TOKEN_SYMBOL = "USDT";
export const APP_LP_TOKEN_SYMBOL = "EQU-BTP";

export const MAINTENANCE_RATE = 0.995;

export const MARK_POINTS = 5;

export const REWARDS_MULTICALL_RATE = 0.95;

export const IS_USER_WHITE_MODEL = false;
export const USER_WHITE_LIST = [
  "0xAeBC662687dC6d07F4092E5B7A8182145f1A198c",
  "0x29a8d64E8a987A6295B6cdb0ED23C6455B856814",
  "0xE41E5C0EeCd05a690CD26Ceb9f4eD625fDfF1E6e",
  "0xa70caDd128CF6aA72042972E78Adf45CD0e8D0fd",
  "0x67d53732857f5bCd0052C863c165694fd759Ee7E",
  "0x94721a19Cd9A55fbcBd9246f405D9FAFb08d21C8",
  "0x0752ce959E25C18266E4F851D5eeB6e2d286AbB4",
  "0x1100Ac9dE4C69b331FFC8E9043C47DA67FACA82b",
  "0x780c82162e8Afe38dA6317b9C29540181207294f",
  "0x78Ec1B4252634778Fe8484BCB38ac315e004806B",
  "0xDdA87Af5A28d56d73087bdeaee437DBc4be5f41d",
  "0x65e1B154c6b066dcE209669506c05bDD2B56C70f",
  "0x3F7EF38e5f0eA281ed701988Bf00086102959E61",
  "0xA98936f62E6CD33dE429Ab6384aeE4ec7AfcCD8B",
  "0x95a91f84D6c30B99e84F64b4dBc4cB40AF67eAEa",
  "0xa1AA6524bF5D022BDDd04D1147E57F30624DD556",
  "0x3B70C25e6aA2e3F58aBE5F64EE66a858cfD9590B",
  "0x4B83B0D111191b04fAE5a518142D516A3A5EF69F",
  "0xf1661E4FCe84054ddD857f28Fe2fB772a1444724",
  "0x0d4e02EDcC3EB6f927675413bD12214Ed6348a4b",
  "0x3f08bb3A44A2B9DFa1Eb14C056c44B33dC9Ae8b5",
  "0xcef39F8e826f650a8934C8E29251632600A0e11c",
  "0x558EB86782EC6456894b5e7e9dC9356e1396f2e8",
  "0x887f79fb13411b0a52d221dA08b0AfB5426c6292",
  "0xB94aF8b68B85e1c64ED0DEF50215715490f72Ed9",
  "0xA6D73528Ff50F11a3647276a7808aBc022fc86fA",
  "0x2fA717F54F3c4af01b47De7c790a3Fe42fb9BdCe",
];

export const MSG_ERROR_APPROVE =
  "execution reverted: ERC20: insufficient allowance";
