import {
  arbitrum as defaultArbitrum,
  arbitrumGoerli as defaultArbitrumGoerli,
  avalanche as defaultAvalanche,
  avalancheFuji as defaultAvalancheFuji,
  goerli as defaultGoerli,
  mainnet as defaultMainnet,
  optimism as defaultOptimism,
} from "wagmi/chains";

import EthereumLogo from "assets/networks/ethereum-logo.png";
import ArbitrumLogo from "assets/networks/arbitrum-logo.svg";
import AvalancheFujiLogo from "assets/networks/avalanche-fuji-logo.svg";
import AvalancheLogo from "assets/networks/avalanche-logo.svg";
import OptimismLogo from "assets/networks/optimism-logo.svg";

import { Env } from "../enum";
import { ChainConfig } from "./types";

export const mainnet: ChainConfig = {
  ...defaultMainnet,
  icon: EthereumLogo,
  rpcUrls: {
    ...defaultMainnet.rpcUrls,
    public: {
      http: ["https://eth.llamarpc.com", "https://ethereum.publicnode.com"],
    },
  },
};

export const goerli: ChainConfig = {
  ...defaultGoerli,
  icon: EthereumLogo,
  rpcUrls: {
    ...defaultGoerli.rpcUrls,
    public: {
      http: [
        "https://goerli.blockpi.network/v1/rpc/public",
        "https://rpc.ankr.com/eth_goerli",
        "https://endpoints.omniatech.io/v1/eth/goerli/public",
      ],
    },
  },
};

export const arbitrum: ChainConfig = {
  icon: ArbitrumLogo,
  ...defaultArbitrum,
  rpcUrls: {
    ...defaultArbitrum.rpcUrls,
    public: {
      http: [
        "https://arb1.arbitrum.io/rpc",
        "https://rpc.ankr.com/arbitrum",
        "https://1rpc.io/arb",
        "https://endpoints.omniatech.io/v1/arbitrum/one/public",
        "https://arbitrum.blockpi.network/v1/rpc/public",
      ],
    },
  },
};

export const arbitrumGoerli: ChainConfig = {
  icon: ArbitrumLogo,
  ...defaultArbitrumGoerli,
  rpcUrls: {
    ...defaultArbitrumGoerli.rpcUrls,
    public: {
      http: [
        "https://arbitrum-goerli.publicnode.com",
        "https://rpc.goerli.arbitrum.gateway.fm",
        "https://arbitrum-goerli.public.blastapi.io",
        "https://arbitrum-goerli.blockpi.network/v1/rpc/public",
        // 'https://api.zan.top/node/v1/arb/goerli/public'
      ],
    },
  },
};

export const optimism: ChainConfig = {
  ...defaultOptimism,
  icon: OptimismLogo,
  rpcUrls: {
    ...defaultOptimism.rpcUrls,
    public: {
      http: [
        "https://mainnet.optimism.io",
        "https://rpc.ankr.com/optimism",
        "https://endpoints.omniatech.io/v1/op/mainnet/public",
        "https://optimism-mainnet.public.blastapi.io",
      ],
    },
  },
};

export const avalanche: ChainConfig = {
  ...defaultAvalanche,
  icon: AvalancheLogo,
  rpcUrls: {
    ...defaultAvalanche.rpcUrls,
    public: {
      http: [
        "https://avalanche.public-rpc.com",
        "https://rpc.ankr.com/avalanche",
        "https://endpoints.omniatech.io/v1/avax/mainnet/public",
        "https://avalanche.blockpi.network/v1/rpc/public",
      ],
    },
  },
};

export const avalancheFuji: ChainConfig = {
  ...defaultAvalancheFuji,
  icon: AvalancheFujiLogo,
  rpcUrls: {
    ...defaultAvalanche.rpcUrls,
    public: {
      http: [
        "https://ava-testnet.public.blastapi.io/ext/bc/C/rpc",
        "https://rpc.ankr.com/avalanche_fuji",
        "https://endpoints.omniatech.io/v1/avax/fuji/public",
        "https://avalanche-fuji-c-chain.publicnode.com",
      ],
    },
  },
};

export const SUPPORTED_CHAINS = [
  (process.env.REACT_APP_TAG as Env) === Env.development
    ? arbitrumGoerli
    : arbitrum,
];

export const SUPPORTED_CHAIN_IDS = SUPPORTED_CHAINS.map((c) => c.id);

export const DEFAULT_CHAIN =
  (process.env.REACT_APP_TAG as Env) === Env.development
    ? arbitrumGoerli
    : arbitrum;
