import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Loading from "./components/loading";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import "katex/dist/katex.min.css";
import { Provider } from "react-redux";
// import { persistStore } from "reduxjs-toolkit-persist";
// import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { store } from "state";
import { SWRConfig } from "swr";
// import { client } from "config/constants/connector/wagmi";
// import { WagmiProvider } from "components/Wallet/WalletProvider";
import Decimal from "decimal.js";

Decimal.set({ precision: 64 });
Decimal.set({ toExpPos: 64 });
Decimal.set({ rounding: Decimal.ROUND_DOWN });

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Suspense fallback={<Loading />}>
    <SWRConfig>
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistStore(store)}> */}
        {/* <WagmiProvider client={client}> */}
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
        {/* </WagmiProvider> */}
        {/* </PersistGate> */}
      </Provider>
    </SWRConfig>
  </Suspense>
);
