import { StyleContainer } from "components/Common/StyleContainer";
import { Box, Typography } from "@mui/material";
import IconPurecash from "../../assets/founder/purecash.svg";

function Hiring() {
  return (
    <StyleContainer className="mt-[80px] sm:mt-[40px]">
      <Typography
        className="mb-[30px] leading-[1.5] text-[32px] sm:mb-[20px] sm:text-[20px]"
        fontWeight={600}
      >
        We're Hiring
      </Typography>

      <Box className="flex sm:flex-col sm:space-y-[20px]">
        <Box className="flex-1">
          <Typography
            className="text-[20px] sm:text-[20px]"
            color="text.secondary"
          >
            Join a vibrant team of professionals from around the world.
            Together, we are driving innovation and creating a more inclusive
            and equitable future. Don’t miss the chance to become part of our
            team!
          </Typography>
        </Box>
        <Box className="w-[400px] flex items-center justify-center sm:w-auto">
          <img
            src={IconPurecash}
            alt="pure.cash"
            className="w-[140px] h-[140px]"
          />
        </Box>
      </Box>
    </StyleContainer>
  );
}

export default Hiring;
