import { Link } from "react-router-dom";
import Logo from "../../assets/logo.svg";

import { StyleContainer } from "components/Common/StyleContainer";

const Header = (props: any) => {
  return (
    <div className="relative z-50">
      <StyleContainer
        className={"flex items-center justify-between h-[80px] sm:h-[86px]"}
      >
        <div className="flex items-center">
          <Link to="/">
            <img src={Logo} alt="logo" className="sm:h-[24px]" />
          </Link>
        </div>
      </StyleContainer>
    </div>
  );
};

export default Header;
