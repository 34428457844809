import { Reducer, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_CHAIN } from "config/constants/connector/chains";
import { ChainConfig } from "config/constants/connector/types";

import { BigNumber } from "ethers";

import { RootState } from "..";

interface StateProps {
  chain: ChainConfig;
  blockHeight: number;
  blockHeightBefore24h: number;
  blockTimestamp: number;
  nativePriceUsd: string;
  gasPrice: BigNumber | null;
  positionMinExecutionFee: string;
  orderMinExecutionFee: string;
  appTimer: number;
}

const initialState: StateProps = {
  chain: DEFAULT_CHAIN,
  blockHeight: 0,
  blockHeightBefore24h: 0,
  blockTimestamp: 0,
  nativePriceUsd: "",
  gasPrice: null,
  positionMinExecutionFee: "",
  orderMinExecutionFee: "",
  appTimer: 0,
};

export const global = createSlice({
  name: "global",
  initialState,
  reducers: {
    setChain(state, { payload }) {
      state.chain = payload;
    },
    setBlockHeight(state, { payload }) {
      state.blockHeight = payload;
    },
    setBlockHeightBefore24h(state, { payload }) {
      state.blockHeightBefore24h = payload;
    },
    setBlockTimestamp(state, { payload }) {
      state.blockTimestamp = payload;
    },
    setNativePriceUsd(state, { payload }) {
      state.nativePriceUsd = payload;
    },
    setGasPrice(state, { payload }) {
      state.gasPrice = payload;
    },
    setPositionMinExecutionFee(state, { payload }) {
      state.positionMinExecutionFee = payload;
    },
    setOrderMinExecutionFee(state, { payload }) {
      state.orderMinExecutionFee = payload;
    },
    setAppTimer(state, { payload }) {
      state.appTimer = payload;
    },
  },
});

export const globalBaseState = (state: RootState) => state.global;

export const {
  setChain,
  setBlockHeight,
  setBlockHeightBefore24h,
  setBlockTimestamp,
  setNativePriceUsd,
  setGasPrice,
  setPositionMinExecutionFee,
  setOrderMinExecutionFee,
  setAppTimer,
} = global.actions;

export default global.reducer as Reducer<typeof initialState>;
