import { Chain } from "wagmi";
import {
  arbitrum,
  arbitrumGoerli,
  baseGoerli,
  goerli,
  mainnet,
  optimism,
  polygon,
} from "wagmi/chains";

export enum ExplorerDataType {
  TRANSACTION = "transaction",
  TOKEN = "token",
  ADDRESS = "address",
  BLOCK = "block",
}

/**
 * Return the explorer link for the given hash and hash type
 * @param chain the chain for which to return the hash
 * @param hash the hash to return a link for
 * @param type the type of the hash
 */

const ETHERSCAN_PREFIXES: Record<number, string> = {
  [mainnet.id]: mainnet.blockExplorers.etherscan.url,
  [goerli.id]: goerli.blockExplorers.etherscan.url,
  [optimism.id]: optimism.blockExplorers.etherscan.url,
  [arbitrum.id]: arbitrum.blockExplorers.etherscan.url,
  [polygon.id]: polygon.blockExplorers.etherscan.url,
  [baseGoerli.id]: baseGoerli.blockExplorers.etherscan.url,
  [arbitrumGoerli.id]: arbitrumGoerli.blockExplorers.etherscan.url,
};

export function getExplorerLink(
  chain: Chain,
  hash: string,
  type: ExplorerDataType
): string {
  let prefix =
    ETHERSCAN_PREFIXES[chain.id] || mainnet.blockExplorers.default.url;
  if (prefix.endsWith("/")) prefix = prefix.slice(0, prefix.length - 1);
  switch (type) {
    case ExplorerDataType.TRANSACTION: {
      return `${prefix}/tx/${hash}`;
    }

    case ExplorerDataType.TOKEN: {
      return `${prefix}/token/${hash}`;
    }

    case ExplorerDataType.BLOCK: {
      return `${prefix}/block/${hash}`;
    }

    case ExplorerDataType.ADDRESS: {
      return `${prefix}/address/${hash}`;
    }
    default: {
      return `${prefix}`;
    }
  }
}
