import { Box, Typography } from "@mui/material";
import { StyleContainer } from "components/Common/StyleContainer";
import "./index.css";

const Banner = () => {
  return (
    <StyleContainer className="mt-[60px] sm:mt-0">
      <Box className="h-[260px] w-full bg-[#1a1e21] rounded-[24px] banner flex justify-center items-center sm:h-[180px] sm:rounded-[14px]">
        <Typography
          variant="h1"
          className="text-[40px] sm:text-[28px] sm:text-center"
          fontWeight={600}
          color="white"
        >
          Next-Gen Payment Network
        </Typography>
      </Box>
      <Box className="flex justify-between mt-[80px] sm:mt-[40px] sm:flex-col-reverse">
        <Box className="w-[520px] sm:w-auto sm:mt-[12px]">
          <Typography variant="h6" className="text-[16px] sm:text-[14px]">
            In an era where the world is becoming increasingly interconnected,
            our vision is to create a decentralized money that transcends
            geographical boundaries, empowering individuals through
            decentralization.
          </Typography>
          <Typography
            variant="h6"
            className="text-[16px] mt-[8px] sm:text-[14px] sm:mt-[4px]"
          >
            We believe in a future where financial systems are not only
            accessible to everyone but are also free from centralized control,
            ensuring fairness, transparency, and stability for all.
          </Typography>
        </Box>
        <Box className="w-[316px] sm:w-auto ">
          <Typography
            variant="h4"
            className="text-[32px] sm:text-[24px]"
            fontWeight={600}
          >
            Pure provides a purely
          </Typography>
          <Typography
            variant="h4"
            className="text-[32px]  sm:text-[24px] "
            fontWeight={600}
          >
            decentralized ecosystem
          </Typography>
        </Box>
      </Box>
    </StyleContainer>
  );
};

export default Banner;
