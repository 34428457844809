export enum Token_Type {
  Input = 0,
  Output = 1,
}

export enum App_Theme {
  light = "light",
  dark = "dark",
}

export enum TipLevel {
  Info = "info",
  Warning = "warning",
  Error = "error",
}

export enum Contract_Write_Mode {
  Unprepared = "recklesslyUnprepared",
  Prepared = "prepared",
}

export enum Maker_Mode {
  Average,
  Arithmetic,
  Geometric,
}

export enum Dom_Size {
  PAGE_CONTAINER = 1440,
  ACTIVITY_CONTAINER = 1200,
  TRADE_FORM = 380,
  LIST_SCOLL = 400,
  CHART = 320,
  PADDING_BASE = 12,
  HEIGHT_HEADER = 64,
  HEIGHT_FOOTER = 182,
  MOBILE_HEIGHT_BOTTOM = 60,
  HEIGHT_LARGE_BUTTON = 48,
  HEIGHT_OPERATION = 345,
  SECTION_LOADING = 160,
  CLAIM_SECTION_LOADING = 224,
}

export enum Media_Query_Size {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1200,
  "2XL" = 1440,
  "3XL" = 1920,
  "4XL" = 2560,
}

export enum Page {
  Index = "/",
  Trade = "/trade",
  Markets = "/markets",
  Pools = "/pools",
  Referrals = "/referrals",
  ReferralsTraders = "/referrals/traders",
  ReferralsEFCMembers = "/referrals/members",
  ReferralsConnectors = "/referrals/connectors",
  Earn = "/earn",
}

export enum Order_Type {
  Open = 0,
  History = 1,
  SwapHistory = 2,
}

export enum Order_Status {
  Created = "Created",
  Executed = "Executed",
  Cancelled = "Cancelled",
}

export enum Order_Open_Type {
  Increase = "Increase",
  Decrease = "Decrease",
}

export enum Orders_Filter_Type {
  All = 0,
  Limit = 1,
  ProfitLoss = 2,
}

export enum History_Filter_Type {
  Trade = 0,
  Position = 1,
  Order = 2,
}

export enum Env {
  development = "development",
  production = "production",
}

export enum Module {
  Trade = "Trade",
  TradeV2 = "TradeV2",
  TradeV3 = "TradeV3",
}

export enum Transaction_Type {
  Approve = "Approve",
  Long = "Long",
  Short = "Short",
  LongLimitOrder = "LongLimitOrder",
  ShortLimitOrder = "ShortLimitOrder",
  UpdateLimitOrder = "UpdateLimitOrder",
  CancelledLimitOrder = "CancelledLimitOrder",
  IncreaseMargin = "IncreaseMargin",
  ReduceMargin = "ReduceMargin",
  IncreaseLiquidity = "IncreaseLiquidity",
  IncreaseMarginLiquidity = "IncreaseMarginLiquidity",
  ReduceMarginLiquidity = "ReduceMarginLiquidity",
  RemoveLiquidity = "RemoveLiquidity",
  Close = "Close",
  TakeProfitStopLossAll = "TakeProfitStopLossAll",
  TakeProfitStopLossFixed = "TakeProfitStopLossFixed",
  CreateTakeProfitPosition = "CreateTakeProfitPosition",
  CreateTakeProfitPartial = "CreateTakeProfitPartial",
  CreateStopLossPosition = "CreateStopLossPosition",
  CreateStopLossPartial = "CreateStopLossPartial",
  UpdateTakeProfitPosition = "UpdateTakeProfitPosition",
  UpdateStopLossPosition = "UpdateStopLossPosition",
  UpdateTakeProfitPartial = "UpdateTakeProfitPartial",
  UpdateStopLossPartial = "UpdateStopLossPartial",
  CancelTakeProfitPosition = "CancelTakeProfitPosition",
  CancelStopLossPosition = "CancelStopLossPosition",
  CancelTakeProfitPartial = "CancelTakeProfitPartial",
  CancelStopLossPartial = "CancelStopLossPartial",
  IncreaseRiskBufferFund = "IncreaseRiskBufferFund",
  ReduceRiskBufferFund = "ReduceRiskBufferFund",
  CreateReferralCode = "CreateReferralCode",
  BindReferralCode = "BindReferralCode",
  ClaimAllReferrals = "ClaimAllReferrals",
  ClaimMembers = "ClaimMembers",
  ClaimConnectors = "ClaimConnectors",
  ClaimAllEarns = "ClaimAllEarns",
  ClaimStake = "ClaimStake",
  ClaimLiqudiity = "ClaimLiqudiity",
  ClaimRBF = "ClaimRBF",
  ClaimArchitect = "ClaimArchitect",
  MintMember = "MintMember",
  StakeAppToken = "StakeAppToken",
  StakeLpToken = "StakeLpToken",
  UnStakeAppToken = "UnStakeAppToken",
  UnStakeLpToken = "UnStakeLpToken",
}

export enum Trade_Type {
  Market,
  Limit,
  Trigger,
}

export enum Trade_Color {
  Long = "long",
  Short = "short",
}

export enum Input_Direction {
  In = "In",
  Out = "Out",
}

export enum Transaction_Status {
  Wallet = "wallet",
  Pending = "pending",
  Complete = "complete",
  Success = "success",
  Error = "error",
  Reject = "reject",
}

export enum Account_Transaction_Type {
  Trades = "trades",
  Orders = "orders",
}

export enum Margin_Adjust_Type {
  Add = "Add",
  Reduce = "Reduce",
}

export enum Impact_Type {
  Auto = "auto",
  Custom = "custom",
}

export enum Price_Benchmark {
  Market = "market",
  Mark = "mark",
}

export enum Profit_Loss_Type {
  Entire = "Entire",
  Partial = "Partial",
}

export enum TakeProfitStopLoss_Type {
  TakeProfit = "takeProfit",
  StopLoss = "stopLoss",
}

export enum Request_Status {
  Cancelled = "Cancelled",
}

export const enum PriceFunctionGroup {
  GROUP_1_1 = 1,
  GROUP_2_1 = 2,
  GROUP_3_1 = 3,
  GROUP_4_1 = 4,
  GROUP_5_1 = 5,
  GROUP_6_1 = 6,
  GROUP_1_2 = 7,
  GROUP_2_2 = 8,
  GROUP_3_2 = 9,
  GROUP_4_2 = 10,
  GROUP_5_2 = 11,
  GROUP_6_2 = 12,
}

export enum Side {
  LONG = 1,
  SHORT = 2,
}

export const SideFlip = (side: Side) =>
  side === Side.LONG ? Side.SHORT : Side.LONG;

export enum PositionOperation {
  PAY = 1,
  SIZE = 2,
}

export enum Stake_Type {
  EQU = "0",
  LP = "1",
}

export enum Claim_Type {
  Member = "Member",
  Connector = "Connector",
  Staking = "Staking",
  Liquidity = "Liquidity",
  RiskBufferFund = "RiskBufferFund",
  Architect = "Architect",
}

export enum Mint_Type {
  Member = "Member",
  Connector = "Connector",
  Architect = "Architect",
}
