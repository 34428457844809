import { StyleContainer } from "components/Common/StyleContainer";
import { Box, Card, Typography } from "@mui/material";

function ContactUs() {
  return (
    <StyleContainer className="mt-[80px] sm:mt-[40px]">
      <Typography
        className="mb-[30px] leading-[1.5] text-[32px] sm:mb-[20px] sm:text-[20px]"
        fontWeight={600}
      >
        Contact Us
      </Typography>
      <Box className="flex space-x-[20px] sm:space-x-0 sm:space-y-[12px] sm:flex-col">
        {[
          {
            title: "People and Talent",
            info: "For career inquiries",
            email: "hr@pure.cash",
          },
          {
            title: "Partnerships",
            info: "For partnership inquiries",
            email: "partnerships@pure.cash",
          },
          {
            title: "Press and Events",
            info: "For media and event requests",
            email: "marketing@pure.cash",
          },
        ].map((item) => {
          return (
            <Card
              className="flex-1 px-[18px] py-[32px] sm:rounded-xl"
              key={item.title}
            >
              <Typography
                className="text-[20px] sm:text-[20px] mb-[24px]"
                fontWeight={600}
                color="text.primary"
              >
                {item.title}
              </Typography>

              <Typography
                className="text-[16px] sm:text-[16px] mb-[40px]"
                color="text.secondary"
              >
                {item.info}
              </Typography>

              <Typography
                className="text-[18px] sm:text-[18px]"
                fontWeight={600}
                color="text.primary"
              >
                <a href={`mailto:${item.email}`}> {item.email}</a>
              </Typography>
            </Card>
          );
        })}
      </Box>
    </StyleContainer>
  );
}

export default ContactUs;
