import React from "react";
import Loading from "../../components/loading";

const NotFound = () => {
  return (
    <>
      <Loading />
    </>
  );
};

export default NotFound;
